@font-face {
    font-family: 'Rubik';
    src: url('../../font/subset-Rubik-Italic.woff2') format('woff2'),
        url('../../font/subset-Rubik-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
} 

@font-face {
    font-family: 'Rubik';
    src: url('../../font/subset-Rubik-Medium.woff2') format('woff2'),
        url('../../font/subset-Rubik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal; 
}

@font-face {
    font-family: 'Rubik';
    src: url('../../font/subset-Rubik-MediumItalic.woff2') format('woff2'),
        url('../../font/subset-Rubik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../../font/subset-Rubik-Regular.woff2') format('woff2'),
        url('../../font/subset-Rubik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

  
h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
	margin: 0px 0px 20px 0px; 
	display: block;
	position: relative;
	font-size: 20px; 
	line-height: 1.2;    
}

h1 {
    font-size: 20px;
	font-weight: 500;  
	line-height: 1.4;
	margin-bottom: 0;
}

h2 {
	font-size: 40px;
	font-weight: normal; 
	line-height: 1.2;
	color: $cRed;
} 

h3 {
	font-size: 40px;
	font-weight: normal; 
    line-height: 1.2; 
    margin-bottom: 10px; 
}

a {  
    @extend %link;
}

.safe-link {
    @extend %link-span;
}


%text-content {
    @extend %cf;
	line-height: 1.43;
	font-family: $font-family;
	font-weight: 300;
    
     
	small { 
		font-size: 12px;
	}

	ul:not(.links):not(.social):not(.stations) { 
		padding: 10px 0px 20px 30px;
		list-style: none;

		li {
			list-style-type:none;
			padding: 0px 0px 0px 0px;
			position: relative;
			margin-bottom: 10px;

			&::before {
				content: "—"; 
				color: inherit;
				display: inline-block; 
				width: 30px;
  				margin-left: -30px
			}

			 
			ol, ul {
				padding-top: 10px;
			}
		} 
	} 

	ol { 
		padding: 10px 20px 0px 30px;
		list-style: none; 
		counter-reset: li;
		letter-spacing: normal;


		li {
			list-style-type:none;
			padding: 0px 0px 10px 0px; 
			counter-increment: li;
			  
			&:before {
				content: counter(li);  
	  			display: inline-block; 
	  			width: 30px;
	  			margin-left: -30px
	  		}
			
			ol, ul {
				padding-top: 10px;
			}
		} 
	} 


	p {
		line-height: 1.5em;
		margin-bottom: 20px; 

		&:last-child{
			margin-bottom: 20px; 
		}
	}

	p + ul {
		margin-top: 0px;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		td {

		}
	}
	
	a {
		color: $cLink; 
		&:hover{
			text-decoration: none;
			color: $cLinkHover;
		}
	}


	blockquote{ 
		color: inherit;
		font-size: 35px; 
		line-height: 1.14; 
		margin-bottom: 20px;
		text-align: center;

	}
 
}
