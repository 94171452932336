.directions{
    @extend %has-buttons;

    > article {
        @extend %text-content; 

			
		figure {
			margin-bottom: 20px;
		} 
    }


}

.maps { 
    @extend %cf;
    @extend %fullwidth; 

    > li {
        position: relative;
        margin-top: 50px;

        &:first-child{
            margin-top: 30px;
        }

        > p {
            display: block;
            padding: 0px $page-padding; 
        }
    }

    em {
        @extend %no;
        position: absolute;
        top: -25px;
        left: 0;
        background-color: $cRedLight;  
        z-index: 2;
          
    } 
 
}

.map-container {
    @extend %cf;
    position: relative;
    z-index: 1;
    margin-bottom: 20px; 
    cursor: pointer; 
     
    img {
        float: left;
        width: 100%;
        height: auto;
    }
}

.zoom { 
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 25px;
    background: url('../../images/zoom-icon.svg') no-repeat center center rgba(255, 255, 255, 0.5);
    background-size: 18px 18px;  

}

.marker {
    display: block;
    width: 39px;
    height: 29px; 
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -16.5px;
    margin-top: -26px;
    background: url('../../images/map-point-hover.svg') no-repeat left bottom transparent;
    background-size: contain;  

}

.red-theme {

}

.blue-theme {

}

.modal-image{ 
    @extend %modal; 
    background-color: $cBg;



    .close {
        top: 14px;
        right: 18px;
    }   
    
    .content {  
        height: calc(100% - 56px); 
        background: #d8d8d8;
    }
}