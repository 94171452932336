.even {
  h2 {
    color: $cBlue;
  }

  .maps em {
    background-color: $cBlue;
  }

  .btn {
    &.red {
      @include focus($cBlueBgHover);
      @extend %arrow-blue;
      color: $cBlue;
      &:hover {
        background-color: $cBlueBgHover;
      }
      &:focus {
        box-shadow: inset 15px 0 15px -15px $cBlue; 
      }
    }
  }
  //

  .progress-bar {
    background-color: $cBlueBg;
    > div {
      background-color: $cBlue;
    }
  }
}
