.result {
  &.final {
  }

  #head {
    //border-bottom: 4px solid $cRed;
    //padding-bottom: 16px;
    //margin-bottom: 15px;
  }
}

.chart-wrap {
  @extend %cf;
  text-align: center;
  margin: 30px auto 30px auto;
  clear: both;

  .c100 {
    margin: auto;
    float: none;
  }
}

.results-by-question {
  @extend %fullwidth;
  clear: both;
  max-height: 0px;
  overflow-y: hidden;
  overflow-x: visible;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;

  .options-wrap {
    width: 100%;
    margin-left: 0px;
  }

  .res-padding {
    padding: 0px $page-padding;
  }

  &.visible {
    max-height: 9000px;
    opacity: 1;
  }
}

.actions {
  display: block;
  margin-bottom: 20px;
  clear: both;
  float: left;
  width: 100%;
  font-style: italic;

  > li {
    display: block;
    clear: both;
    margin-bottom: 10px;

    > a {
      display: inline-block;
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.with-logos {
    margin-top: 20px;
    > li {
      margin-bottom: 20px;

      > a {
        @extend %link-span;
        &:before {
          content: "";
          display: block;
          position: relative;
          clear: both;
          height: 50px;
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
    .rafm {
      &:before {
        height: 40px;
        background: url("../../images/rafm-logo.svg") no-repeat left bottom transparent;
        background-size: contain;
      }
    }
    .museum {
      &:before {
        background: url("../../images/rafmlogo.svg") no-repeat left bottom transparent;
        background-size: contain;
      }
    }
  }
}

.results-set {
  padding-top: 10px;

  .question {
    margin-bottom: 20px;
  }

  .options-wrap {
    @extend %cf;
    @extend %fullwidth;
    padding-top: 20px;

    ol {
      li {
        cursor: auto;
        background-color: $cw;

        &:before {
        }

        &:hover,
        &.selected {
          background-color: $cw;
        }

        &.selected {
          color: $cCorrect;
          background-color: $selectedOption;
        }

        &.missed {
          color: $cCorrect;
          &:before {
          }
        }

        &.wrong {
          color: $cIncorrect;
          text-decoration: line-through;
          &:before {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

%toggle {
  content: "";
  position: absolute;
  top: 17px;
  right: 0;
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  transition: all 0.3s ease-out;
}

.single-result {
  .single-result-questions,
  .question-body {
    height: 0px;
    overflow-y: hidden;
    overflow-x: visible;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .question-body {
    > p {
      display: block;
      padding: 0px $page-padding;
    }
  }

  h2 {
    padding-bottom: 15px;
    border-bottom: 2px solid $cRed;
    margin-bottom: 15px;
    cursor: pointer;

    &:after {
      @extend %toggle;
      background: url("../../images/arr-red.svg") no-repeat center center transparent;
      background-size: 10px 16px;
    }

    &:focus {
      color: $cLinkHover; 
    }
  }

  .question {
    h3 {
      cursor: pointer;
      &:after {
        @extend %toggle;
        top: 13px;
        background: url("../../images/arr-black.svg") no-repeat center center transparent;
        background-size: 10px 16px;
      }

      &:focus {
        color: $cLinkHover;
      }
    }

    &.visible {
      .question-body {
        height: auto;
        opacity: 1;
      }

      h3 {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.visible {
    .single-result-questions {
      padding-bottom: 20px;
      height: auto;
      opacity: 1;
    }

    h2 {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}
