.sections-list{
    @extend %cf;
    @extend %fullwidth; 
    > li { 

        &:nth-child(odd) {
            a {
                background-color: $cRedBg; 
                strong {
                    @extend %arrow-red;
                    background-color: transparent;
                    color: $cRed;
                }

                em {
                    background-color: $cRedLight;  
                }

                &:focus { 
                        background-color: $cRedBgHover; 
                } 
            }
        }
        &:nth-child(even) {
            a {
                background-color: $cBlueBg;
                strong { 
                    @extend %arrow-blue;
                    background-color: transparent;
                    color: $cBlue;
                }
                em {
                    background-color: $cBlue;
                }

                &:focus { 
                    background-color: $cBlueBgHover; 
                }
            }
        }

        &:first-child {
            
        }

    }
    figure {
        @extend %landscape;
    } 
    a {
        display: block;
        position: relative;
        text-decoration: none;  
        color: $cPrimary;
        border: none;
           
        &:hover{
            color: inherit;
        }
    }

    div { 
        position: relative;
        padding: 40px 20px 20px; 
    }

    em {
        @extend %no;
        position: absolute;
        top: -25px;
        left: 0;
        
          
    }

    strong {
        display: block;
        font-size: 30px;
        font-weight: normal;
        text-transform: uppercase; 
        padding: 0px 0px 0px;
    }

    span {
        font-size: 20px;
    }
}
