#exiting{
    @extend %modal; 

    article {
        @extend %text-content;
        font-size: 40px;
        background-color: $cw;
        width: 100%;
        padding: $page-padding $page-padding 100px;
    }

    .bottom{
        position: absolute; 
    }
}