
* {
	box-sizing: border-box;
	outline: 0; 
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;   

    font-family: $font-family; 
    font-weight: normal;
    z-index: 1;
}

%has-buttons {
    padding-bottom: 100px; // hass buttons 
}


html, body { 
	height: 100%;
	background-color: $cBg; 
	font-family: $font-family;   
	line-height: $line-height; 
    color: $cPrimary;   
    font-size: $font-size;   

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} 

body{
    &.is-safari {   
        //overflow-y: scroll;
        //-webkit-overflow-scrolling: touch;
    }
}
  

  
header, footer, section, main{
	@extend %cf;  
	padding: 0;
	max-width: $max-width;
	margin:0px auto; 
	z-index: 9;
	position: relative; 
	margin: auto; 
	width:100%; 
	display: block; 
    padding: 0px;
    clear: both;
    opacity: 1;
    transition: opacity 0.3s ease-in;
    
}


section, main{  
    &.has-sticky{
        padding-top: 52px;
    }
}  

#root {
    min-height: 100%;    

    > div { 

        display: grid;
        grid-template-areas:"app";
        //min-height: 100%;  
        min-height: 100vh; 
        max-width: 768px;
        width: 100%;
        margin: auto;
        position: relative;  
        
        > div {  
            backface-visibility: hidden;
            height: 100%; 
            grid-area: app;
        }
    }

    @include respond-to(tablet-portrait) {  
        > div {
            overflow: hidden;
        } 
	} 
    
    
}
   
.page{  
	background-color: $cBg;  
    z-index: 11;
    position: relative;
    top: 0px; 
    left: 0px;    
    
    /* 
    position: absolute; 
    */
	margin: auto;    
	width:100%; 
    height: 100%;  
    min-height: 100%;
    //overflow: auto;  
    padding: $page-padding;  

    // transform: translateX(0);
    //transition: all 500ms;
    
    transition: left 300ms ease-out;
    //transition-delay: 0.2s;


	@include respond-to(tablet-portrait) {  
	} 

	@include respond-to(desktop) {  
	}  
}  
 

.wrap{
	@extend %cf; 
	position: relative;
	margin: auto;
	max-width: $full-width;
	display: block;


	@include respond-to(tablet-portrait) { 
 		

	} 
	@include respond-to(desktop) { 

	} 
}
 
img{
	max-width: 100%;
	height: auto; 
	vertical-align: bottom;
	&.fw {
		width: 100%;
	}
}  