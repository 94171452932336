%modal{
    position: fixed;
    top: 0px;
    left: 0;
    //width: 0px;
    //height: 0px;
    overflow: hidden;
    background-color: rgba(238, 238, 238, 0.62);
    opacity: 0;
    pointer-events: none;
    z-index: 999;
    width: 100%;
    height: 100%;
    
    transition: opacity 0.2s ease-in-out; 


    .content { 
        bottom: -100vh; 
        position: absolute; 
        left: 50%; 
        max-width: 768px; 
        width: 100%;
        transform: translate(-50%, 0%);
        transition: opacity 0.1s ease-in-out, bottom 0.5s ease-in-out; 

    }

    &.visible {
        pointer-events: auto; 
        opacity: 1;
        z-index: 999; 
        

        .content {
            bottom: 0px;
        }
    }

}