// Variables ===================================================================================== /

$cb:          hsl(0, 0%, 0%); 
$cw:          hsl(0, 0%, 100%); 
$cBg:         #eeeeee;
$cPrimary:	  #252525;   
$cRedBg:      #ffd6d6;
$cRedBgHover: #f7bfbf;
$cRed:        #d52424; 
$cRedLight: #e64444; 

$cBlueBg:     #d3eafc;
$cBlueBgHover:#bde0fc;
$cBlue:       #2023ac;
$cLink:       #d52424; 
$cLinkHover:  #8a1a1a;  

$selectedOption: #e9f6ff; 
$focusColor: #AFCFE8;
$cCorrect: #407e1f;
$cIncorrect: #d52424; 

  
$font-family:      		'Rubik', sans-serif;
  
$font-size:     		20px;
$vertical-measure: 		1em;   
$line-height: 			1.43;

$max-width:     		2700px; //1359px; // set page max-width
$full-width:    		1460px; // set page max-width  

$gutter-width:	   		20px;     // set gutter width 
$gutter-half-width:	   	10px;     // set gutter width
$page-padding:	     	24px;      

$minw-mp: 		   		320px; 
$minw-mxl: 		   		375px;  
$minw-ml:			    480px;    
$minw-tp: 		   		750px;  
$minw-tl:		  		1024px;  
$minw-d: 				1100px;    
$minw-dxl: 				1400px;   