.homepage {  
    @extend %has-buttons;
    //@extend #head;
    h1 {
        margin-bottom: 15px;
    }

    > article {
        position: relative;
        z-index: 9;
    }
}

.logo{
	display: block; 
    width: 81px;
    height: 51px;
    float: left;
    background: url('../../images/rafmlogo.svg') no-repeat left center transparent;
    background-size: contain; 
    border: none;
    margin-bottom: 20px;

    
    span {
        display: none;
    }

	@include respond-to(mobile-large) {    
	}

	@include respond-to(tablet-portrait) {    
	} 
}
  
header{  
    @extend %cf;
    z-index: 99;
    
	.open {
		top: 0;
		right: 10px;
		background: url('../../images/back.svg') no-repeat right center transparent;
		background-size: 44px 34px; 
	}  
}

.read-more{ 
    @extend %link-span;

    height: 25px;
    float: left; 
    clear: both; 
    display: block;
    margin-top: 10px;
    font-style: italic;

    cursor: pointer;
    
}

.more { 
    float: left;
    clear: both;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    margin-bottom: -10px;
    padding-top: 15px;


    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out; 
    
    > div { 
        @extend %text-content;
    }
    
    
    &.visible {
        
        max-height: 1200px;
        opacity: 1;
    }

} 

.hpi { 
    @extend %fullwidth;  
    z-index: 1;
    position: relative;
    top: -60px;
    vertical-align: top;
    img { 
    }
}