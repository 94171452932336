#head {
  position: fixed;
  padding: 31px $page-padding 21px;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $cBg;
  z-index: 99;
  border-bottom: 1px solid transparent;
  left: 50%;
  width: 100%;
  max-width: 768px;
  transform: translate(-50%, 0%);
  transition: border-color 300ms ease-out, padding 200ms ease-out;

  .close {
    top: 30px;
  }

  .back {
    top: 30px;
  }

  > article {
    h1 {
      line-height: 25px;
    }

    h2 {
      margin: 0;
      padding: 15px 0px 0px;
    }
  }

  &.scrolling {
    border-bottom-color: #dbdbdc;
    padding: 21px $page-padding;

    .close {
      top: 20px;
    }

    .back {
      top: 20px;
    }
  }
}

.subtitle {
  margin-bottom: 25px;
}
