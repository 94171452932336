.sponsors {
    @extend %cf;

    img {
        max-width: 100px;
        max-height: 100px;
        float: left;
    }

    li {
        @extend %cf;
        display: block;
        clear: both;
        padding: 0px 0px 20px;
    }

    a { 
        @extend %link-span;
        clear: both;
        float: left;
        
    }
}   