%arrow-red {
  background: url("../../images/arrow-red.svg") no-repeat right center $cRedBg;
  background-size: 55px 16px;
  background-origin: content-box;
}

%arrow-blue {
  background: url("../../images/arrow-blue.svg") no-repeat right center $cBlueBg;
  background-size: 55px 16px;
  background-origin: content-box;
}

%close {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 22px;
  top: 21px;
  background: url("../../images/close.svg") no-repeat center center transparent;
  background-size: 16px 16px;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in;
}

%back {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  left: 17px;
  top: 21px;
  background: url("../../images/back.svg") no-repeat center center transparent;
  background-size: 10px 16px;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in;
}

.bottom {
  @extend %cf;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  z-index: 11;
  max-width: 768px;
  transform: translate(-50%, 0%);
  transition: left 300ms ease-out;

  &.two {
    .btn {
      width: 50%;
      width: calc(50% - 3px);
      float: left;

      + .btn {
        float: right;
      }
    }
  }
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}

@mixin focus($color) {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    background-color: $color;
    display: block;
    position: absolute;
    border-radius: 50%;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.001, 0.001);
  }

  &.clicked {
    outline: 0;
    &:after {
      animation: effect_dylan 0.3s ease-out;
    }
  }
}

.btn {
  display: block;
  padding: 12px 20px;
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  border-bottom: none;
  cursor: pointer;

  transition: all 300ms ease-out;

  &.red {
    @include focus($cRedBgHover);
    @extend %arrow-red;
    color: $cRed;

    &:hover {
      background-color: $cRedBgHover;
    }
    &:focus {
    	box-shadow: inset 15px 0 15px -15px $cRed;
    }
  }

  &.na {
    background-image: none !important;
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: inset 0px 0 0px 2px $cRed; 
  }
}

.close {                            
  @extend %close;
  &:focus {
    box-shadow: inset 0px 0 0px 30px rgba(20, 20, 20, 0.1);
  }
}

.back {
  @extend %back;
  &:focus {
    box-shadow: inset 0px 0 0px 30px rgba(20, 20, 20, 0.1);
  }
}
