%cf{
	&:before, &:after { content: ""; display: table; clear: both; } 
}

%no {
    height: 50px;
    line-height: 50px; 
    padding: 0px 25px;
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    color: $cw;
}

%link-span {
    color: $cLink;
    border-bottom: none;
    > span {   
        color: $cLink;
        border-bottom: 1px solid $cLink;
    }   

    &:hover, &:focus {  
        border-bottom: none;
        text-decoration: none;
        color: $cLinkHover;
        > span {
            color: $cLinkHover;
            border-bottom-color: $cLinkHover;
        }
    }
}

%link { 
    color: $cLink;
    border-bottom: 1px solid $cLink;  
    
    &:hover {
        text-decoration: none; 
        color: $cLinkHover;
        border-bottom-color: $cLinkHover; 
    }
} 

%fullwidth {
    margin-left: -$page-padding;
    width: calc(100% + #{2*$page-padding});

} 

%rounded { 
	border-radius: 50%;
	overflow: hidden;

} 
 
%square{
	&:after{
		content: "";
		display: block;
		padding-bottom: 100%; 
	}
}
 
%rectangle{
	&:after{
		content: "";
		display: block;
		padding-bottom: 50%; 
	}
}

%landscape{
	&:after{ 
		content: "";
		display: block;
		padding-bottom: 56.25%; 
	}
} 

.invisible {
    opacity: 0;
    visibility: hidden;
}

.bgi {
    background-size: cover;
	background-position: center top;
	background-repeat: no-repeat; 
	position: relative;
	min-height: 100px;
	display: block;

	> img{
		display: none !important;
	}
}